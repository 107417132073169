import xhr from './xhr/';
/**
 * 数据统计相关的 API
 */
class StatisticsService {
	// 运动数据搜索
	getSportData(data) {
		return xhr({
			method: 'get',
			url: '/sport/search',
			params: data,
		});
	}

	// 血压数据搜索
	getBloodData(data) {
		return xhr({
			method: 'get',
			url: '/blood/search',
			params: data,
		});
	}

	// 血糖数据搜索
	getSugarData(data) {
		return xhr({
			method: 'get',
			url: '/sugar/search',
			params: data,
		});
	}

	// 尿酸数据搜索
	getUricData(data) {
		return xhr({
			method: 'get',
			url: '/uric/search',
			params: data,
		});
	}

	// 血脂数据搜索
	getChoData(data) {
		return xhr({
			method: 'get',
			url: '/cho/search',
			params: data,
		});
	}

	// 身高腰围数据搜索
	getHeightData(data) {
		return xhr({
			method: 'get',
			url: '/height/search',
			params: data,
		});
	}

	// 人体成分数据搜索
	getBodyData(data) {
		return xhr({
			method: 'get',
			url: '/body/search',
			params: data,
		});
	}

	// 握力数据搜索
	getGripData(data) {
		return xhr({
			method: 'get',
			url: '/grip/search',
			params: data,
		});
	}

	// 肺活量数据搜索
	getCapacityData(data) {
		return xhr({
			method: 'get',
			url: '/capacity/search',
			params: data,
		});
	}

	// 平衡能力数据搜索
	getBalancedData(data) {
		return xhr({
			method: 'get',
			url: '/balanced/search',
			params: data,
		});
	}

	// 训练数据搜索
	getTrainData(data) {
		return xhr({
			method: 'get',
			url: '/trans/search',
			params: data,
		});
	}

	// 膳食数据搜索
	getFoodData(data) {
		return xhr({
			method: 'get',
			url: '/food/search',
			params: data,
		});
	}

	// 方案数据搜索
	getPlanData(data) {
		return xhr({
			method: 'get',
			url: '/plan/search',
			params: data,
		});
	}

	// 服务数据获取
	getServicesSelect(data) {
		return xhr({
			method: 'get',
			url: '/services/index',
			params: data,
		});
	}

	// 服务数据搜索
	getServicesData(data) {
		return xhr({
			method: 'get',
			url: '/services/list',
			params: data,
		});
	}

	// 促进数据搜索
	getBoostData(data) {
		return xhr({
			method: 'get',
			url: '/boost/search',
			params: data,
		});
	}

	// 促进数据搜索
	getDoctorData(data) {
		return xhr({
			method: 'get',
			url: '/doctor/search',
			params: data,
		});
	}

	// 预警数据搜索
	getWarningData(data) {
		return xhr({
			method: 'get',
			url: '/warning/search',
			params: data,
		});
	}

	// 问题数据搜索
	getProblemData(data) {
		return xhr({
			method: 'get',
			url: '/problem/search',
			params: data,
		});
	}

	// 会员分数排名
	getRankingData(data) {
		return xhr({
			method: 'get',
			url: '/member/score',
			params: data,
		});
	}

	// 服用数据搜索
	getDrugData(data) {
		return xhr({
			method: 'get',
			url: '/drug/search',
			params: data,
		});
	}

	// 质检数据搜索
	getQualityData(data) {
		return xhr({
			method: 'get',
			url: '/quality/search',
			params: data,
		});
	}

	// 疾病统计搜索
	getSickData(data) {
		return xhr({
			method: 'get',
			url: '/sick/search',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new StatisticsService();
